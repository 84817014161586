.scroll-container {
  width: 40rem;
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.nav-menu {
  position: absolute;
  display: flex;
  bottom: 10.15rem;
  z-index: 1;
  padding: 0.7rem 0.6rem;
  border-radius: 0.5rem;
  background-color: rgb(20 20 20 / 70%);
  min-width: 100%;
}

.nav-menu-item-cont {
  margin: 0rem 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(97 97 97);
}

.nav-sub-menu-item-cont {
  border: none;
}

.nav-menu-item-overlay {
  background-color: rgb(0 0 0 / 40%);
  backdrop-filter: blur(0.75px);
  width: 4rem;
  height: 4rem;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: inherit;
}

.nav-sub-menu-item-overlay {
  background-color: rgb(0 0 0 / 0%);
}

.nav-menu-item-overlay:hover {
  background-color: rgb(0 0 0 / 15%);
  border-radius: inherit;
  backdrop-filter: blur(0px);
}

.nav-sub-menu-item-overlay:hover {
  background-color: rgb(0 0 0 / 0%);
}

.nav-menu-item {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
}

.nav-menu-item-info {
  position: absolute;
  display: flex;
  z-index: 3;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.3rem;
}

.nav-sub-menu-item-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  pointer-events: none;
}

.nav-menu-item-title {
  color: white;
  z-index: 2;
  border-radius: inherit;
  pointer-events: none;
}

.nav-menu-item img{
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
